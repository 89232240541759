import React from "react";
import NavBarContainer from "../container/NavBarContainer";
import FormHome from "../container/FormHome";
import Footer from "../components/Footer/Footer";
import { useAuth0 } from "../react-auth0-wrapper";
import Bubble from "../shared/loadPage/Bubble";

const HomePage = () => {

  const { isAuthenticated, loginWithRedirect, loading, user, logout } = useAuth0();
  if (loading) {
    return <Bubble />;
  }
  return (
    <>
      <NavBarContainer
        isAuthenticated={isAuthenticated}
        loginWithRedirect={loginWithRedirect}
        user={user}
        logout={logout}
      />
      <FormHome />
      <Footer />
    </>
  );
};

export default HomePage;