import React from 'react';
import "./styles.css";
import Button from "../Button/Button";

const linkAccountAlexa = () => {
    const state =  localStorage.getItem('state'); 
    localStorage.removeItem('state');
    window.location.href = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/authorize${state}`;
}

const Footer = () => {
    return (
        <div className="Footer">
            <div className="button-group">
                <Button white description="Return to Alexa" onClick={() => linkAccountAlexa()} />
                <Button blue description="Finish" onClick={() => linkAccountAlexa()} />
            </div>
        </div>
    );
};

export default Footer;