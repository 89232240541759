import React from 'react';
import PropTypes from 'prop-types';
import './loading-bubbles.css';
import Bubble from "../shared/loadPage/Bubble";

export const withLoading = Component => {

  const WithLoad = ({ data }) =>
    data
      ?
      <Component data={data} />
      :
      <Bubble />

  WithLoad.propTypes = {
    data: PropTypes.bool
  }

  return WithLoad;
};