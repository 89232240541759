import React from 'react';
import "./styles.css";

const Bubble = () => {
    return (
        <div className="bubbles">
            <div className="bubble1"></div>
            <div className="bubble2"></div>
            <div className="bubble3"></div>
        </div>
    );
};

export default Bubble;