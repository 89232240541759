// src/react-auth0-wrapper.js
import React, { useState, useEffect, useContext } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
import axios from "axios";

const DEFAULT_REDIRECT_CALLBACK = () =>
    window.history.replaceState({}, document.title, window.location.pathname);

export function setTokenApi(accessToken) {
    localStorage.setItem('token', accessToken);
}

export function clearTokenApi() {
    localStorage.removeItem('token');
}

export function getTokenApi() {
    return localStorage.getItem('token');
}

export function setStatepi(accessToken) {
    localStorage.setItem('state', accessToken);
}

export function clearStateApi() {
    localStorage.removeItem('state');
}

export function getStateApi() {
    return localStorage.getItem('state');
}

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({
    children,
    onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
    ...initOptions,
}) => {
    const [isAuthenticated, setIsAuthenticated] = useState();
    const [user, setUser] = useState();
    const [auth0Client, setAuth0] = useState();
    const [loading, setLoading] = useState(true);
    const [popupOpen, setPopupOpen] = useState(false);

    const [accessFree, setAccessFree] = useState(false);
    const [accessBasic, setAccessBasic] = useState(false);
    const [accessPaid, setAccessPaid] = useState(false);
    const [soarIdAuth0, setSoarIdAuth0] = useState(0);

    function verifyUserAcess(user) {
        axios.get(process.env.REACT_APP_SOAR_API_ENDPOINT + 'user/' + user, (req, res) => {
            res.status(200).send();
        }).then(function (response) {
            setAccessFree(true);
            if (response.data.subscriptions[1]) {
                setAccessBasic(true);
            }
            if (response.data.subscriptions[2]) {
                setAccessPaid(true);
            }
        });
    }

    async function updateFieldAuth0(user) {
        try {
            const httpClient = axios.create({});
            const soarUserUrl = process.env.REACT_APP_SOAR_API_ENDPOINT + `user/${user['https://www.soar.com.app_metadata'].soar_id}`;
            const data = {};
            data.auth0Id = user.sub;
            const result = await httpClient.patch(soarUserUrl, data);
            return result.data;
        } catch (error) {
            console.log('createSoarUser error: ', error);
        }
    }

    const updateAuth0User = (userSoarId) => {
        axios.post(process.env.REACT_APP_BACKEND + 'auth0sandler',
            {
                data: {
                    url: process.env.REACT_APP_SOAR_API_ENDPOINT + 'user/sendIdToAuth0/' + userSoarId,
                }
            },
        ).then(function (response) {
            verifyUserAcess(userSoarId);
        });
    }

    const createSoarUser = async (user) => {
        try {
            const httpClient = axios.create({});
            const soarUserUrl = process.env.REACT_APP_SOAR_API_ENDPOINT + 'user';
            const data = {};
            data.emails = [];
            data.customFields = {};
            data.emails[0] = { emailAddress: user.email, type: 'WORK' };
            data.auth0Id = user.sub;
            data.givenName = user.name || null;
            data.familyName = user.name.familyName || null;
            data.SignupCode = 7;
            data.GSNStatus = 110;
            const gravatarMatch = user.picture.match('cdn.auth0.com%2Favatars');
            data.customFields.SandlerAlerts = true;
            data.customFields.SanderPromoMessages = true;
            if (gravatarMatch !== null) {
                data.customFields.ProfilePictUrl = '';
            } else {
                data.customFields.ProfilePictUrl = user.picture || '';
            }
            // TODO: Add more profile data
            const result = await httpClient.post(soarUserUrl, data);
            await updateAuth0User(result.data.id);
            setSoarIdAuth0(result.data.id);
            return result.data;
        } catch (error) {
            console.log('createSoarUser error: ', error);
        }
    }

    useEffect(() => {
        const initAuth0 = async () => {
            const auth0FromHook = await createAuth0Client(initOptions);
            setAuth0(auth0FromHook);
            if (window.location.search.includes("code=")) {
                const { appState } = await auth0FromHook.handleRedirectCallback();
                const token = await auth0FromHook.getTokenSilently();
                setTokenApi(token);
                onRedirectCallback(appState);
            }
            const isAuthenticated = await auth0FromHook.isAuthenticated();
            setIsAuthenticated(isAuthenticated);

            if (isAuthenticated) {
                const user = await auth0FromHook.getUser();
                setUser(user);
                if (parseInt(user['https://www.soar.com.app_metadata'].soar_id) > 0) {
                    updateFieldAuth0(user);
                    verifyUserAcess(user['https://www.soar.com.app_metadata'].soar_id);
                } else {
                    //otherwise, create a user                    
                    await createSoarUser(user);
                }
            }
            setLoading(false);
        };
        var url_string = window.location.href;
        if ("https://sandlerdev.soar.com/setup?client_id" === url_string.substring(0, 43)) {
            setStatepi(url_string.substring(33));
        }
        initAuth0();
        // eslint-disable-next-line
    }, []);

    const loginWithPopup = async (params = {}) => {
        setPopupOpen(true);
        try {
            await auth0Client.loginWithPopup(params);
        } catch (error) {
            console.error(error);
        } finally {
            setPopupOpen(false);
        }
        const user = await auth0Client.getUser();
        setUser(user);
        setIsAuthenticated(true);
    };

    const handleRedirectCallback = async () => {
        setLoading(true);
        await auth0Client.handleRedirectCallback();
        const user = await auth0Client.getUser();
        setLoading(false);
        setIsAuthenticated(true);
        setUser(user);
    };

    return (
        <Auth0Context.Provider
            value={{
                isAuthenticated,
                user,
                loading,
                popupOpen,
                loginWithPopup,
                handleRedirectCallback,
                accessFree,
                accessBasic,
                accessPaid,
                soarIdAuth0,
                getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
                loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
                getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
                getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
                logout: (...p) => auth0Client.logout(...p)
            }}
        >
            {children}
        </Auth0Context.Provider>
    );
};