import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "./react-auth0-wrapper";
import config from "./auth_config.json";
import 'purecss';
import { useAuth0 } from "./react-auth0-wrapper";
import dotenv from 'dotenv'
dotenv.config()
// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
    window.history.replaceState(
        {},
        document.title,
        appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
    );
};

function LoadingApp() {
    const { isAuthenticated } = useAuth0();
    return <App data={window.location.search.includes("code=") ? isAuthenticated : true} />
}

ReactDOM.render(
    <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
        redirect_uri={process.env.REACT_APP_URL + '/setup'}
        audience={process.env.REACT_APP_AUTH0_API_ENDPOINT}
        scope={config.scope}
        intent={config.intent}
        protocol={config.protocol}        
        onRedirectCallback={onRedirectCallback}
    >
        <LoadingApp />
    </Auth0Provider>,
    document.getElementById("root")
);

serviceWorker.unregister();