import React from "react";
import NavBar from "../components/NavBar/NavBar";

const NavBarContainer = (props) => {
    return (        
        <NavBar
            title="Setup Alexa Skill"
            loginWithRedirectAuth0={() => props.loginWithRedirect({})}
            isAuthenticatedAuth0={props.isAuthenticated}
            logout={props.logout}
            user={props.user}
        />
    );
};

export default NavBarContainer;