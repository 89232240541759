import React, { useState, useEffect } from 'react';
import "./styles.css";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faBars, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const NavBar = (props) => {    

    const [showMenuMobile, setShowMenuMobile] = useState(false);
    const [appElement, setAppElement] = useState(null);

    useEffect(() => {
        setAppElement(document.querySelector('body'));

        return () => clearAllBodyScrollLocks();
    }, []);

    const menuMobileEnable = () => {
        enableBodyScroll(appElement);
        setShowMenuMobile(false);
    }
    
    const menuMobileDisable = () => {
        disableBodyScroll(appElement);
        setShowMenuMobile(true);
    }
    
    const menuMobileToggle = () => {
        if (showMenuMobile) menuMobileEnable();
        else menuMobileDisable();
    }

    const getFirstTwoLettersLoginEmail = email =>  email.substr(0,2).toUpperCase();

    return (
        <header className="NavBar">

            <nav className="upside">
                <div className="logo">
                    <img src={process.env.PUBLIC_URL+'/img/sandler_logo.png'} alt="Sandler" />
                </div>
                
                {true && (
                    <>
                        <div className="group-itens">
                            <Link className="item" to="/">Configure</Link>
                            <Link className="item disabled-link" to="/">Voice Commands</Link>
                        </div>
                        <div className="container-avatar">
                            <div className="avatar">
                                {getFirstTwoLettersLoginEmail(props.user.name)}

                            </div>
                            <FontAwesomeIcon icon={faSortDown} className="sort-down"/>
                        </div>

                        <div className="icon-menu-mobile" onClick={() => menuMobileToggle()}>
                            {!showMenuMobile && <FontAwesomeIcon icon={faBars} className="icon-hambuger" />}
                            {showMenuMobile && <FontAwesomeIcon icon={faTimesCircle} className="icon-x-circle" />}
                        </div>                        
                    </>
                )}
            </nav>

            {showMenuMobile &&
                <div className="group-itens-mobile">
                    <Link className="item-mobile-up" to="/">Configure</Link>
                    <Link className="item-mobile-up disabled-link" disabled to="/">Voice Commands</Link>
                    <div className="divider-mobile"></div>
                    <Link className="item-mobile-under" to="/">Get Help</Link>
                    <Link className="item-mobile-under" to="/">Give Feedback</Link>
                    <Link className="item-mobile-under" to="/">Logout</Link>
                </div>
            }

            <div className="divider"></div>

            <div className="underside">
                <h1>{props.title}</h1>
            </div>

        </header>
    );
};

export default NavBar;