import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles.css';
import FieldSoarUnderInfo from "../FieldSoarUnderInfo/FieldSoarUnderInfo";
import classNames from "classnames";

class FieldSoar extends Component {
  
  static propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.oneOf(['text', 'password', 'number', 'email', 'checkbox']),
    phone: PropTypes.bool,
    email: PropTypes.bool,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    buttonText: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    onClick: PropTypes.func,   
    onBlur : PropTypes.func,   
    value: PropTypes.string,
    maxLength: PropTypes.string,
    workEmailError: PropTypes.string,
    workEmailSuccess: PropTypes.string,
    personalEmailError: PropTypes.string,
  };

  static defaultProps = {
    id: null,
    label: null,
    type: 'text',
    phone: false,
    email: false,
    placeholder: null,
    name: null,
    buttonText: null,
    checked: true,
    value: '',
    onChange:  () => {},
    onClick:  () => {},
    onBlur: () => {},
    maxLength: '',
    workEmailError: '',
    workEmailSuccess: '',
    personalEmailError: '',
  };

  render() {
    const { id,
      label,
      type,
      placeholder,
      name,
      buttonText,       
      onClick,
      onChange,
      checked,
      value,
      onBlur,
      maxLength,
      workEmailError,
      workEmailSuccess,
      personalEmailError,
    } = this.props;

    const labelChunk = (
      <label className="FieldHolderLabel" htmlFor={id}>
        {label}
      </label>
    );

    switch (type) {
      case 'checkbox': {
        return (
          <div className="FieldSoar FieldSoarCheckbox">
            <div className="FieldHolder">
            <input id={id}
                name={name}
                type={type}                
                className="FieldHolderInput"
                checked={checked} 
                onChange={onClick}
              />
              <span className="checkmark" onClick={onClick} onChange={onChange}></span>
              {labelChunk}
            </div>
          </div>
        )
      }

      default: {
        return (
          <div className="FieldSoar FieldSoarInput">
            <div>
              {labelChunk}
            </div>
            <div>
              {!buttonText &&
                <>
                  <input id={id}
                    name={name}
                    type={type}
                    maxLength={maxLength}
                    placeholder={placeholder}   
                    defaultValue={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    className={classNames({'FieldError': personalEmailError,})}
                  />
                  {personalEmailError && <FieldSoarUnderInfo info={personalEmailError} error />}
                </>
              }
              {buttonText &&
                <>
                  <div className="input-with-button">
                    <input id={id}
                      name={name}
                      type={type}
                      maxLength={maxLength}
                      placeholder={placeholder}
                      defaultValue={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      className={classNames({'FieldError': workEmailError,'FieldSuccess': workEmailSuccess,})}
                    />
                    <div className="inner-button" onClick={onClick}>{buttonText}</div>
                  </div>
                  {
                    workEmailError ? <FieldSoarUnderInfo info={workEmailError} error /> :
                      workEmailSuccess ? <FieldSoarUnderInfo info={workEmailSuccess} success /> : null
                  }
                </>
              }
            </div>
          </div>
        );
      }
    }
  }
}

export default FieldSoar;
