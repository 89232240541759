import React, { PureComponent }  from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.css';

class Button extends PureComponent {

    static propTypes = {
        className: PropTypes.string,
        type: PropTypes.oneOf(['button', 'submit']),
        blue: PropTypes.bool,
        white: PropTypes.bool,
        description: PropTypes.string,
        onClick: PropTypes.func,
    };

    static defaultProps = {       
        type: 'button',
        className: '',
        blue: false,
        white: false,
        description: '',
        onClick: () => {},
      };

    render() {
        const {
          className,
          white,
          blue,
          onClick,
          description
        } = this.props;

        const buttonClassNames = classNames('soar-button', {
            [className]: className,
            'soar-button_theme_white': white,
            'soar-button_theme_blue': blue,
        });

        return (
            <div type="button" className={buttonClassNames} onClick={onClick}>
                <div>{description}</div>
            </div>
        )
    }
};

export default Button;
