import React, { useState, useEffect } from "react";
//import CardSoar from "../components/CardSoar/CardSoar";
import ContainerSoar from "../components/ContainerSoar/ContainerSoar";
import FieldSoar from "../components/FieldSoar/FieldSoar";
import LabelAccessSoar from "../components/LabelAccessSoar/LabelAccessSoar";
import InputGroup from "../components/InputGroup/InputGroup";
import { useAuth0 } from "../react-auth0-wrapper";
import axios from "axios";

function FormHome() {

    const { user, accessFree, accessBasic, accessPaid, soarIdAuth0 } = useAuth0();
    const [stateCheckedEd, setStateCheckedEd] = useState(true);
    const [stateCheckedAlert, setStateCheckedAlert] = useState(true);
    const [workEmail, setWorkEmail] = useState();
    const [workEmailError, setWorkEmailError] = useState();
    const [workEmailSuccess, setWorkEmailSuccess] = useState();
    const [personalEmail, setPersonalEmail] = useState();
    const [personalEmailError, setPersonalEmailError] = useState();
    const [personalEmailSuccess, setPersonalEmailSuccess] = useState();
    const [phoneNumber, setPhoneNumber] = useState();
    const REGEX_EMAIL = /^[_A-Za-z0-9-\\+]+(\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9]+)*(\.[A-Za-z]{2,})$/;
    const REGEX_EMPTY = /[^\\ ]+/;

    useEffect(() => {
        async function loadRepositories() {
            const httpClient = axios.create({});
            const soarUserUrl = process.env.REACT_APP_SOAR_API_ENDPOINT + ((soarIdAuth0 > 0) ? `user/${soarIdAuth0}` : `user/${user['https://www.soar.com.app_metadata'].soar_id}`);
            const response = await httpClient.get(soarUserUrl);
            setStateCheckedEd(response.data.customFields.SanderPromoMessages === "False" ? false : true);
            setStateCheckedAlert(response.data.customFields.SandlerAlerts === "False" ? false : true);
            setWorkEmail(response.data.customFields.SandlerSubscriptionEmail);
            setPersonalEmail(response.data.emails[0].emailAddress);
            setPhoneNumber(response.data.phones[0].phoneNumber);
        }
        if (user['https://www.soar.com.app_metadata'].soar_id > 0 || soarIdAuth0 > 0) {
            loadRepositories();
        }
    }, [user, soarIdAuth0])

    const handleChangeEd = event => {
        setStateCheckedEd(!stateCheckedEd);
        updateSoarCustomField(true, false);
    };
    const handleChangeAlert = event => {
        setStateCheckedAlert(!stateCheckedAlert);
        updateSoarCustomField(false, true);
    };

    const updateSoarCustomField = async (promo, alert) => {
        try {
            const httpClient = axios.create({});
            const soarUserUrl = process.env.REACT_APP_SOAR_API_ENDPOINT + ((soarIdAuth0 > 0) ? `user/${soarIdAuth0}` : `user/${user['https://www.soar.com.app_metadata'].soar_id}`);
            const data = {};
            data.customFields = {};
            if (promo) {
                data.customFields.SanderPromoMessages = !stateCheckedEd;
            }
            if (alert) {
                data.customFields.SandlerAlerts = !stateCheckedAlert;
            }
            const result = httpClient.patch(soarUserUrl, data);
            return result.data;
        } catch (error) {
            console.log('createSoarUser error: ', error);
        }
    }

    const updateSoarContactUser = async () => {
        if (workEmail || personalEmail || phoneNumber) {
            const httpClient = axios.create({});
            let soarUserUrl = process.env.REACT_APP_SOAR_API_ENDPOINT + ((soarIdAuth0 > 0) ? `user/${soarIdAuth0}` : `user/${user['https://www.soar.com.app_metadata'].soar_id}`);
            let data = {};
            if (isEmpty(personalEmail)) {
                if (personalEmailValidator()) {
                    let contact = await httpClient.get(soarUserUrl);
                    data.emails = [];
                    data.emails[0] = { emailAddress: contact.data.emails[0].emailAddress, type: contact.data.emails[0].type }
                    data.emails[1] = { emailAddress: personalEmail, type: "Personal" };
                }
            }
            if (phoneNumber) {
                data.phones = [];
                data.phones[0] = { phoneNumber: phoneNumber, type: "Sandler" }
            }
            const result = httpClient.patch(soarUserUrl, data);
            return result.data;
        }
    }

    const isEmail = email => REGEX_EMAIL.test(email);
    const isEmpty = field => REGEX_EMPTY.test(field);

    const workEmailValidator = () => {
        if (workEmail && isEmail(workEmail)) {
            setWorkEmailError("");
            return true;
        }

        setWorkEmailSuccess("");
        setWorkEmailError("Email is invalid. Please try another email.");
        return false;
    }

    const personalEmailValidator = () => {
        if (personalEmail && isEmail(personalEmail)) {
            setPersonalEmailError("");
            setPersonalEmailSuccess("");
            return true;
        }

        setPersonalEmailSuccess("");
        setPersonalEmailError("Email is invalid. Please try another email.");
        return false;
    }

    const callAPI = () => {
        if (!isEmpty(workEmail)) {
            setWorkEmailError("Email is invalid. Please try another email.");
            setWorkEmailSuccess("");
            return;
        }
        if (workEmailValidator()) {

            axios.post(process.env.REACT_APP_BACKEND + 'sendEmail',
                {
                    data: {
                        email: workEmail,
                        user: ((soarIdAuth0 > 0) ? `${soarIdAuth0}` : `${user['https://www.soar.com.app_metadata'].soar_id}`)
                    }
                },

            );
            setWorkEmailError("");
            setWorkEmailSuccess("Please check the email address above for a verification link.");
        }
    }

    return (
        <form>
            <ContainerSoar title="Account Information" description="">
                <InputGroup>
                    <FieldSoar
                        id="work-email"
                        label="Sandler Online Account Email"
                        type="email"
                        placeholder="Enter your work email (optional)"
                        name="workemail"
                        buttonText="VERIFY"
                        value={workEmail}
                        maxLength="250"
                        onChange={e => setWorkEmail(e.target.value)}
                        onClick={() => { callAPI() }}
                        onBlur={() => { updateSoarContactUser() }}
                        workEmailError={workEmailError}
                        workEmailSuccess={workEmailSuccess}
                    />
                    <LabelAccessSoar
                        description="Free access to 300+ audio files"
                        icon={accessFree}
                        unLock={accessFree} />
                    <LabelAccessSoar
                        description="Basic content locked. Verify your work email with Sandler for expanded access."
                        icon={accessBasic}
                        unLock={accessBasic} />
                    <LabelAccessSoar
                        description="Premium content locked. Learn more"
                        linkTitle="here"
                        linkUrl="#"
                        icon={accessPaid}
                        unLock={accessPaid} />
                </InputGroup>

                <InputGroup>
                    <FieldSoar
                        id="personal-email"
                        label="Personal Email"
                        type="email"
                        placeholder="Enter your personal email (optional)"
                        name="personalemail"
                        value={personalEmail}
                        maxLength="250"
                        onChange={e => setPersonalEmail(e.target.value)}
                        onBlur={() => { updateSoarContactUser() }}
                        personalEmailError={personalEmailError}
                        personalEmailSuccess={personalEmailSuccess}
                    />
                    <FieldSoar
                        id="educational-notifications"
                        label="I want to receive educational content and promotions from Sandler via email."
                        name="educational-notifications"
                        type="checkbox"
                        checked={stateCheckedEd}
                        onClick={e => handleChangeEd(e.target.id)} />
                </InputGroup>

                <InputGroup>
                    <FieldSoar
                        id="mobile-phone"
                        label="Mobile Phone"
                        placeholder="Enter your mobile number (optional)"
                        name="mobilephone"
                        type="text"
                        value={phoneNumber}
                        maxLength="19"
                        onChange={e => setPhoneNumber(e.target.value)}
                        onBlur={() => { updateSoarContactUser() }} />
                    <FieldSoar
                        id="general-general"
                        label="I want to receive infrequent text message alerts from Sandler that will help maximize value from the Alexa skill and other Sandler services."
                        name="general-general"
                        type="checkbox"
                        checked={stateCheckedAlert}
                        onClick={e => handleChangeAlert(e.target.id)} />
                </InputGroup>

            </ContainerSoar>
            {/* <ContainerSoar
                    title="Add-Ons"
                    description="If you have purchased additional integrations for the Alexa app from the Sandler online store, you can integrate them now. Learn more here.">
                    <div className="pure-g">
                        <CardSoar
                            imageUrl={process.env.PUBLIC_URL + '/img/google_calendar_logo.png'}
                            title="Google Calendar"
                            description="See upcoming events and get details about attendees."
                            linkText="Learn More"
                            linkUrl="#">
                            <LabelAccessSoar
                                description="LOCKED"
                                icon={faLock}
                                lockCardOrange />
                        </CardSoar>
                        <CardSoar
                            imageUrl={process.env.PUBLIC_URL + '/img/sales_force_logo.png'}
                            title="Salesforce"
                            description="Get information about your Salesforce contacts & opportunities."
                            linkText="Learn More"
                            linkUrl="#">
                            <LabelAccessSoar
                                description="LOCKED"
                                icon={faLock}
                                lockCardOrange />
                        </CardSoar>
                    </div>
                </ContainerSoar>/*/}
        </form>
    );
};

export default FormHome;