import React from "react";
import classNames from "classnames";
import PropTypes from 'prop-types';
import './styles.css';

const FieldSoarUnderInfo = (props) => {

    const { error, success , info } = props;

    const infoClass = classNames('FieldSoarUnderInfo', {
        'FieldSoarUnderInfo-error': error,
        'FieldSoarUnderInfo-success': success,
    });

    return (
        <small className={infoClass}>{info}</small>
    );
}

FieldSoarUnderInfo.propTypes = {
    error: PropTypes.bool,
    success: PropTypes.bool,
}

FieldSoarUnderInfo.defaultProps = {
    error: false,
    success: false,
}

export default FieldSoarUnderInfo;