import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import './styles.css';

class LabelAccessSoar extends Component {

    static propTypes = {
        className: PropTypes.string,
        unLock: PropTypes.bool,
        lock: PropTypes.bool,
        available: PropTypes.bool,
        lockCardOrange: PropTypes.bool,
        linkTitle: PropTypes.string,
        linkUrl: PropTypes.string,
    };

    static defaultProps = {               
        className: '',        
        unLock: false,
        lock: true,
        available: false,
        lockCardOrange: false,
        linkTitle: '',
        linkUrl: '',
    };

    render() {
        const { icon, description, className, lock, unLock, available, lockCardOrange, linkTitle, linkUrl } = this.props;
        const labelClassNames = classNames('LabelAccessSoar', {
            [className]: className,
            'LabelAccessSoar_grey': lock,
            'LabelAccessSoar_green': unLock,
            'LabelAccessSoar_blue': available,
            'LabelAccessSoar_CardOrange': lockCardOrange
        });
        switch (icon) {
            case true: {
                return (
                    <div className={labelClassNames} >
                        <div className="label-icon">
                            <FontAwesomeIcon icon={faLockOpen} />
                        </div>
                        {!linkUrl && (
                            <span className="label-description">{description}</span>
                        )}
                        {linkUrl && (
                            <span className="label-description">
                                {description}{" "}<a href={linkUrl}>{linkTitle}</a>{"."}
                            </span>
                        )}                
                    </div>
                );
            }
            default: {
                return (
                    <div className={labelClassNames} >
                        <div className="label-icon">
                            <FontAwesomeIcon icon={faLock} />
                        </div>
                        {!linkUrl && (
                            <span className="label-description">{description}</span>
                        )}
                        {linkUrl && (
                            <span className="label-description">
                                {description}{" "}<a href={linkUrl}>{linkTitle}</a>{"."}
                            </span>
                        )}                
                    </div>
                );
            }
        }
    }
}

export default LabelAccessSoar;