import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import './App.css';
import PrivateRoute from "./components/PrivateRoute";
import "./index.css";
import HomePage from "./container/HomePage";
import SoarPage from "./container/SoarPage";
import { withLoading } from "./hocs/withLoading";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={SoarPage} />
          <PrivateRoute exact path="/setup" component={HomePage} />          
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default withLoading(App);
// profile email users