import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './styles.css';

class ContainerSoar extends Component {

  static propTypes = {        
    title: PropTypes.string, 
    description: PropTypes.string,
    linkTitle: PropTypes.string,
    linkUrl: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node),PropTypes.node])
  };

  static defaultProps = {               
    title: '',  
    description: '',
    children: null,
    linkTitle: '',
    linkUrl: '',
  };

  render() {
    const {          
      title,
      description,
      children,
      linkTitle,
      linkUrl,
    } = this.props;
    
    return (
        <div className="ContainerSoar">
            <div className="container-header">            
              <h3 className="title">{title}</h3> 
              {description && (
                <p className="description">
                  {description}{" "}<a href={linkUrl}>{linkTitle}</a>{"."}
                </p>
              )} 
            </div>
            <div>
                {children}
            </div>        
        </div>
    )
  }
};

export default ContainerSoar;